import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '@core/services/file.service';
import { take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { StatusIconComponent } from '../status-icon/status-icon.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgIf, NgClass, NgSwitch, NgSwitchCase } from '@angular/common';

export type FileItem = {
  name: string;
  hasRemove?: boolean;
  status?: 'uploading' | 'succeeded' | 'failed' | 'invalid';
  link?: string;
};

export type FileItemStatus = FileItem['status'];
@Component({
  selector: 'nxt-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatIcon,
    NgClass,
    MatIconButton,
    NgSwitch,
    NgSwitchCase,
    StatusIconComponent,
    MatProgressSpinner,
    TranslateModule,
  ],
})
export class FileItemComponent {
  @Input({ required: true }) name!: FileItem['name'];
  @Input() hasRemove: FileItem['hasRemove'];
  @Input() status: FileItem['status'];
  @Input() link: FileItem['link'];
  @Input({ transform: booleanAttribute }) wrapFileName = false;

  @Output() fileRemoved = new EventEmitter<void>();
  @Output() fileDownloadStarted = new EventEmitter<void>();

  constructor(private fileService: FileService) {}

  removeFile() {
    this.fileRemoved.emit();
  }

  downloadFile() {
    this.fileService
      .downloadFile({
        name: this.name,
        hasRemove: this.hasRemove,
        status: this.status,
        link: this.link,
      })
      .pipe(take(1))
      .subscribe(() => this.fileDownloadStarted.emit());
  }
}
