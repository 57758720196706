/* eslint-disable no-useless-escape */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ariaLabel',
  standalone: true,
})
export class AriaLabelPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      let transformedValue = value;

      transformedValue = transformedValue.replace(/[-\/]/g, '');

      transformedValue = transformedValue.replace(/<br>/g, '');

      return transformedValue;
    }

    return value;
  }
}
