<div class="file-item">
  <div class="underlined file-item-left">
    <div class="d-flex align-items-center file-name-container"
      [attr.title]="name"
      [class.status-success-color] = "status === 'succeeded'"
      [class.status-error-color] = "status === 'failed' || status === 'invalid'">
      <button class="me-auto w-100 d-flex justify-content-center"
        mat-button
        color="primary"
        *ngIf="link; else noLink"
        (click)="downloadFile()">
          <mat-icon class="material-icons-outlined">file_download</mat-icon>
          <span
            class="underlined-text"
            [ngClass]="wrapFileName ? 'wrapped' : 'ellipsized'">{{ name }}</span>
      </button>
      <ng-template #noLink>
        <span
          class="me-auto"
          [ngClass]="wrapFileName ? 'wrapped' : 'ellipsized'">{{ name }}</span>
      </ng-template>
    </div>
    <div class="d-flex align-items-center">
      <button class="ms-auto"
        *ngIf="hasRemove"
        mat-icon-button
        [color]="link ? 'primary' : 'accent'"
        (click)="removeFile()"
        [attr.aria-label]="'COMMON.AXA.UPLOAD.CANCEL' | translate">
          <mat-icon class="material-symbol-outlined">cancel</mat-icon>
      </button>
    </div>
  </div>
  <div class="status-icon-container"
    [ngSwitch]="status">
      <nxt-status-icon
        *ngSwitchCase="'succeeded'"
        statusValue="OK" />
      <nxt-status-icon
        *ngSwitchCase="'failed'"
        statusValue="ERROR" />
      <nxt-status-icon
        *ngSwitchCase="'invalid'"
        statusValue="INVALID" />
      <mat-spinner
        *ngSwitchCase="'uploading'"
        diameter="21" />
  </div>
</div>
