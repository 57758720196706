import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FileItem } from '@shared/components/file-item/file-item.component';
import { HttpClient } from '@angular/common/http';
import { of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  private renderer: Renderer2;

  constructor(
    private httpClient: HttpClient,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  downloadFile(fileItem: FileItem) {
    fileItem = this.cleanFileName(fileItem);
    if (!fileItem?.link) {
      return of({} as Blob);
    }
    return this.httpClient.get(fileItem.link, { responseType: 'blob' }).pipe(
      tap((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const downloadLink: HTMLAnchorElement = this.renderer.createElement('a') as HTMLAnchorElement;
        this.renderer.setAttribute(downloadLink, 'href', url);
        this.renderer.setAttribute(downloadLink, 'download', fileItem.name ?? 'temp-filename.zip');
        this.renderer.setStyle(downloadLink, 'display', 'none');

        this.renderer.appendChild(document.body, downloadLink);

        downloadLink.click();

        URL.revokeObjectURL(url);
        this.renderer.removeChild(document.body, downloadLink);
      })
    );
  }

  private cleanFileName(fileItem: FileItem): FileItem {
    const indexLastDot = fileItem.name.lastIndexOf('.');
    const indexFirstSpaceAfterLastDot = fileItem.name.indexOf(' ', indexLastDot);

    if (indexLastDot !== -1 && indexFirstSpaceAfterLastDot !== -1) {
      fileItem.name = fileItem.name.substring(0, indexFirstSpaceAfterLastDot);
    }
    return fileItem;
  }
}
